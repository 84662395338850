(function($) {
	$(function() {
		$('#contact-form').submit(function(e) {
			e.preventDefault();

			var hasErrors = false;
			var contactForm = $(this);
			var formdata = contactForm.serialize();

			// Check if any of the fields are empty
			// Add error class if no value is found
			if ($('#enquiry').val() == 'Please select from the list below') {
				$('#enquiry').addClass('has-error');

				hasErrors = true;
			} else {
				$('#enquiry').removeClass('has-error');
			}

			var fields = ['gitname', 'gitphone', 'gitemail', 'gitmessage', 'gitcompany'];
			var field_count = fields.length;
			var valid_fields = 0;
			$.each( fields, function( i, value ) {
				if ($('#' + value).val() == '') {
					$('#' + value).addClass('has-error');

					hasErrors = true;
				} else {
					valid_fields++;

					$('#' + value).removeClass('has-error');
				}
			});

			if (field_count == valid_fields) {
				$('.alert-danger.contact-form-incomplete').addClass('hide');
			}

			if ($('.g-recaptcha-response').val() == '') {
				hasErrors = true;

				$('.alert-danger.contact-form-captcha').removeClass('hide');
			} else {
				$('.alert-danger.contact-form-captcha').addClass('hide');
			}

			// If errors are found, show the error notification
			if (hasErrors) {
				$('.alert-danger.contact-form-incomplete').removeClass('hide');

				return false;
			}

			// If we get this far the form has been filled in correctly and we can proceed with sending the data
			$.ajax({
				type : 'POST',
				dataType : 'json',
				url : contactFormAjax.ajaxurl,
				data : {
					action: 'contactformsubmit',
					formdata: formdata,
					security : contactFormAjax.security,
				},
				success: function(data) {
					if (data.error == false) {
						// Log the event
						var analyticsData = {
							'name' : $('#gitname').val(),
							'company' : $('#gitcompany').val(),
							'contact_number' : $('#gitphone').val(),
							'email' : $('#gitemail').val(),
							'enquiry_type' : $('#enquiry').val(),
							'message' : $('#gitmessage').val(),
							'contact_option' : $('input[name="contact-option"]:checked').val()
						};
						analyticsTracking('contactSellerFormSubmit', analyticsData);

						// Send an event to Google Analytics
						if (typeof ga === 'function' || typeof window.ga !== 'undefined') {
							ga('send', 'event', 'Submit get in touch form', 'click', 'Get in touch form');
						}

						// Hide all error notifications
						$('.alert-danger').addClass('hide');

						// Remove all error classes from the form
						$('select, input, textarea, checkbox, radio', contactForm).each(function() {
							$(this).removeClass('has-error');
							// And the checkbox
							$(this).parent().removeClass('has-error');

							hasErrors = false;
						});

						// Show the success notification
						$('.alert-success').removeClass('hide');

						// Reset the form fields
						contactForm.trigger('reset');

						// Reset the captcha
						grecaptcha.reset();
					} else {
						// Show the error notification
						$('.alert-danger.contact-form-submission-failed').removeClass('hide');

						if (data.captcha) {
							// Reset the captcha
							grecaptcha.reset();

							$('.alert-danger.contact-form-captcha').removeClass('hide');
						}
					}
				}
			});
		});
	});
})( jQuery );
